import { deleteLocalStorage, getLocalStorage } from "../Functions/LocalStorage";
import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
import { getCookieConsentValue } from "react-cookie-consent";
import Classement from "../Components/Classement";
import { faPersonWalkingDashedLineArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeDefiFromIdToId } from "../Functions/ChangeDefiId";

const Layout = (props) => {
    return (
        <div>
            {props.children}
        </div>
    )
}

const Equipe = () => {
    const { idEquipe } = useParams();

    const createEquipe = () => {
        const equipeName = document.getElementById("equipeName") as HTMLInputElement
        const equipeNameValue = equipeName.value
        if (equipeNameValue === "") {
            alert("Veuillez entrer un nom d'équipe valide")
            return
        }
        const defiId = getLocalStorage("defiId")
        fetch(process.env.REACT_APP_URL_BACK + "/api/equipe", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: equipeNameValue,
                defiId: defiId
            })
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                alert("Une erreur est survenue lors de la création de votre équipe.")
            }
        }).then((data) => {
            if (data !== undefined) {
                deleteLocalStorage(undefined)
                localStorage.setItem("defiId", defiId)
                localStorage.setItem("equipeId", data._id)
                localStorage.setItem("equipeName", data.name)
                const textQrCode = window.location.origin + "/equipe/" + data._id
                localStorage.setItem("equipeURL", textQrCode)
                window.location.reload()
            } else {
                alert("Une erreur est survenue lors de la création de votre équipe.")
            }
        })
    }
    const shareLink = (equipeUrl) => {
        const shareNavigator = () => {
            return new Promise((resolve, reject) => {
                if (navigator.share) {
                    navigator.share({ url: equipeUrl })
                        .then(() => {
                            resolve("Partage réussi par navigator.share")
                        })
                        .catch(() => {
                            reject()
                        })
                } else {
                    reject()
                }
            })
        }
        const shareClipbopard = () => {
            return new Promise((resolve, reject) => {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(equipeUrl)
                        .then(() => {
                            resolve("Partage réussi par navigator.clipboard")
                        })
                        .catch(() => {
                            reject()
                        })
                } else {
                    reject()
                }
            })
        }

        shareNavigator()
            .then((result) => {
                console.log(result)
            })
            .catch(() => {
                shareClipbopard()
                    .then((result) => {
                        console.log(result)
                    })
                    .catch(() => {
                        console.log("Partage échoué par navigator.share et navigator.clipboard")
                    })
            })
    }

    if (!getCookieConsentValue("cookieConsent")) {
        return (
            <Layout>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <p>Le site sauvegarde les données d&apos;Équipes dans les Cookies, veuillez les acceptez 🍪 ci-dessous</p>
                </div>
            </Layout>
        )
    }

    const equipeId = getLocalStorage("equipeId")
    const equipeName = getLocalStorage("equipeName")
    const equipeURL = localStorage.getItem("equipeURL")
    if (equipeId === null || equipeName === null || equipeURL === null) {
        if (idEquipe !== undefined) {
            const messageErreurGetEquipe = "<div>Une erreur est survenue lors de la récupération de votre équipe. Assurez-vous d'être sur la bonne url. Sinon je vous invite à créer une nouvelle équipe en cliquant sur l'onglet  <a href=\"/equipe\">ÉQUIPE</a>.</div>"
            fetch(process.env.REACT_APP_URL_BACK + "/api/equipe/" + idEquipe, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    document.getElementById("messageGetEquipe").innerHTML = messageErreurGetEquipe
                }
            }).then((data) => {
                if (data !== undefined) {
                    if (data.defiId !== getLocalStorage("defiId")) {
                        changeDefiFromIdToId(getLocalStorage("defiId"), data.defiId)
                    }
                    localStorage.setItem("equipeId", data._id)
                    localStorage.setItem("equipeName", data.name)
                    const textQrCode = window.location.origin + "/equipe/" + data._id
                    localStorage.setItem("equipeURL", textQrCode)
                    window.location.href = "/equipe"
                } else {
                    document.getElementById("messageGetEquipe").innerHTML = messageErreurGetEquipe
                }
            }).catch((error) => {
                console.log(error)
                document.getElementById("messageGetEquipe").innerHTML = messageErreurGetEquipe
            })
            return (
                <Layout>
                    <h3 id={"messageGetEquipe"}
                        style={{ textAlign: "center", color: "white", marginTop: "40px" }}>Chargement de votre équipe en
                        cours...</h3>
                </Layout>
            )
        }
        return (
            <Layout>
                <h3 style={{ textAlign: "center", color: "white", marginTop: "40px" }}><p>Scannez votre numéro d&apos;équipe ou
                    cliquez sur votre lien d&apos;équipe pour en rejoindre une.</p>
                    <p style={{ marginTop: "6px" }}>Ou créez votre équipe ci-dessous:</p>
                </h3>
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <input type="text" id="equipeName" placeholder="Entrez votre nom d'équipe"
                        style={{ height: "40px", borderRadius: "10px", margin: "auto" }} />
                    <p style={{ margin: "10px" }}></p>
                    <button type="button" style={{ height: "40px", padding: "0 10px", borderRadius: "10px" }}
                        onClick={createEquipe}>Créer votre Équipe
                    </button>
                </div>

            </Layout>
        )
    } else {
        return (
            <div>
                <Layout>
                    <h3 style={{ textAlign: "center", color: "white", marginTop: "40px" }}>
                        <p>Vous êtes l&apos;équipe: {equipeName}</p>
                        <p>Envoyez l&apos;url ci-dessous aux membres de votre équipe pour synchroniser votre avancée ou
                            faîtes-leurs scanner le QrCode:</p></h3>
                    <div style={{ textAlign: "center", margin: "10px" }} onClick={() => shareLink(equipeURL)}>
                        <a href={equipeURL}>{equipeURL}</a> <br /> <QRCode value={equipeURL}
                            style={{ marginTop: "25px" }} />
                    </div>
                    <Classement />
                    <h3 style={{ textAlign: "center", color: "white", marginTop: "40px" }}>
                        <p>Quitter l&apos;équipe: <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight}
                            onClick={() => deleteLocalStorage("Etes-vous sûre de vouloir quitter votre équipe ? (Cela ne supprimera pas son avancement)")}
                            style={{ cursor: "pointer" }} /></p>
                    </h3>
                </Layout>
            </div>
        )
    }
}

export default Equipe
